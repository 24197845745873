
import { defineComponent } from 'vue';
import Panel from '@/components/layout/Panel.vue';
import ViewMixin from '@/mixins/ViewMixin';
import TextField from '@/components/controls/TextField.vue';
import Button from '@/components/controls/Button';
import * as userService from '@/services/identity/userService';
import * as roleService from '@/services/identity/roleService';
import { User } from '@/interfaces/identity/user';
import LoadingIndicatorBeam from '@/components/loading/LoadingIndicatorBeam.vue';
import DataItem from '@/components/layout/DataItem.vue';
import { ROLE_ADMINISTRATOR } from '@/constants/roles';
import { Role } from '@/interfaces/identity/role';
import DateTimeFormatter from '@/components/formatters/DateTimeFormatter.vue';

interface Data {
    searchTerm: string;
    isLoading: boolean;
    users: User[];
    roles: Role[];
}

export default defineComponent({
    name: 'Users',
    components: {
        Button,
        DataItem,
        LoadingIndicatorBeam,
        Panel,
        TextField,
        DateTimeFormatter,
    },
    mixins: [ViewMixin],
    data: (): Data => ({
        searchTerm: '',
        isLoading: false,
        users: [],
        roles: [],
    }),
    computed: {
        isAdmin(): boolean {
            return this.$store.getters['authentication/hasOneRoles']([ROLE_ADMINISTRATOR]);
        },
    },
    methods: {
        async handleRoleAssignment(user: User, role: Role): Promise<void> {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            try {
                if (user.roles.includes(role.name)) {
                    await userService.removeUserFromRole(user.id, {
                        roleId: role.id,
                    });
                    this.$notify({
                        type: 'success',
                        text: this.$t('roleRemoved'),
                    });
                } else {
                    await userService.assignUserToRole(user.id, {
                        roleId: role.id,
                    });
                    this.$notify({
                        type: 'success',
                        text: this.$t('roleAssigned'),
                    });
                }
                this.loadUsers();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                this.$notify({
                    type: 'error',
                    text: error,
                });
            }
            this.isLoading = false;
        },
        async refreshData(): Promise<void> {
            await this.loadRoles();
            await this.loadUsers();
        },
        async loadUsers(): Promise<void> {
            this.isLoading = true;
            try {
                const response = await userService.getMultiple({
                    pageSize: -1,
                    searchQuery: this.searchTerm,
                });
                this.users = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
        async loadRoles(): Promise<void> {
            this.isLoading = true;
            try {
                const response = await roleService.getMultiple({
                    pageSize: -1,
                });
                this.roles = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
    },
    created(): void {
        this.setPageTitle([this.$t('users'), this.$t('identity')]);
        this.refreshData();
    },
});
